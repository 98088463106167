<template>
    <!-- Button trigger modal -->
    <div
        class="btn btn-sm border border-dark"
        id="infoButton"
        data-bs-toggle="modal"
        data-bs-target="#infoModal">
        <img class="pe-none" src="/images/icons/question-mark-icon.svg" alt="info-button" width="20" height="20">
    </div>
    <!-- Modal -->
    <div class="modal fade"
         id="infoModal"
         data-bs-backdrop="static"
         data-bs-keyboard="false"
         tabindex="-1"
         aria-labelledby="originModalLabel"
         aria-hidden="true"
    >
        <div class="modal-dialog modal-lg modal-dialog-centered modal-dialog-scrollable">
            <div class="modal-content">
                <div class="modal-header">
                    <h1 class="modal-title fs-5" id="originModalLabel">Over de interactieve database</h1>
                </div>
                <InfoText class="modal-body"/>
                <div class="modal-footer">
                    <button type="button"
                            class="btn btn-primary"
                            data-bs-dismiss="modal">
                        OK
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import InfoText from "./InfoText";

export default {
    name: "DatabaseInfoTextModal",
    components: {
        InfoText,
    }
}
</script>

<style scoped>

</style>
