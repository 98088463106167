<template>
    <div
        class="d-flex flex-column justify-content-center align-items-center mt-5"
        v-if="showFallbackText">
        <p class="fw-bold">
            Er is geen data beschikbaar voor deze filters.
        </p>
    </div>
    <div v-else>
        <img v-if="this.data.length > 0"
             id="downloadTableBtn"
             class="float-end"
             src="/images/icons/download-icon.svg"
             alt="download-icon"
             height="15"
             width="15"
             role="button"
             @click="downloadCSV"
        >
        <table class="table text-center d-block w-100">
            <thead>
            <tr>
                <th scope="col"
                    v-for="(header, index) of headers" :key="index">
                    {{ header }}
                </th>
            </tr>
            </thead>
            <tbody class="table-group-divider">
            <tr v-for="(item, index) of data" :key="index">
                <td v-for="(header, indexHeader) of headers" :key="indexHeader">
                    {{ item[header] }}
                </td>
            </tr>
            </tbody>
        </table>
    </div>
</template>

<script>
export default {
    name: "Table",
    computed: {
        showFallbackText() {
            return this.apiResponseReceived && (this.data === null || this.data.length === 0)
        }
    },
    props: {
        headers: Array,
        data: Array,
        apiResponseParams: Object,
        apiResponseReceived: Boolean,
        indicatorName: String,
    },
    methods: {
        downloadCSV() {
            let tableRows = document.querySelectorAll('tbody > tr')
            let csvContent = 'data:text/csv;charset=utf-8,'
            csvContent += this.headers.join(';') + '\r\n'
            tableRows.forEach(tableRow => {
                let row = []
                let cols = tableRow.querySelectorAll('td')
                cols.forEach(col => {
                    row.push(col.innerText)
                })
                csvContent += row.join(';') + '\r\n'
            })
            let encodedUri = encodeURI(csvContent);
            let link = document.createElement('a');
            link.setAttribute('href', encodedUri);
            link.setAttribute('download', `${this.indicatorName}.csv`);
            document.body.appendChild(link);
            link.click();
        },
    }
}
</script>

<style scoped>

table {
    overflow-x: auto !important;
}

#downloadTableBtn:hover {
    filter: invert(76%) sepia(15%) saturate(1262%) hue-rotate(166deg) brightness(85%) contrast(94%);
}

</style>
