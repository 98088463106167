<template>
    <img :src="imgSrc" :height=height alt="">
</template>

<script>
export default {
    name: "ImgBanner",
    props:{
        imgSrc: String,
        height: String,
    }
}
</script>

<style scoped>

img {
    object-fit: cover;
    width: 100%;
}

</style>
