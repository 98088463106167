<template>
    <Link class="nav-link text-white" :class="{'text-decoration-underline fw-bold' : active }">
        <slot/>
    </Link>
</template>

<script>
import { Link } from '@inertiajs/vue3'

export default {
    name: "NavLink",
    components: {
        Link,
    },
    props: {
        active: Boolean,
    }
}
</script>
