<template>
        <!-- Button trigger modal -->
        <div class="me-lg-2">
            <img role="button" src="/images/icons/info-icon-circle-black.svg" alt="info-button" height="27" width="27"
                 data-bs-toggle="modal" data-bs-target="#infoModal" id="infoButton">
        </div>
        <!-- Modal -->
        <div class="modal fade"
             id="infoModal"
             data-bs-backdrop="static"
             data-bs-keyboard="false"
             tabindex="-1"
             aria-labelledby="infoModalLabel"
             aria-hidden="true"
        >
            <div class="modal-dialog modal-lg modal-dialog-centered">
                <div class="modal-content">
                    <div class="modal-header">
                        <h1 class="modal-title fs-5" id="infoModalLabel">Informatie over deze data</h1>
                    </div>
                    <div class="modal-body">
                        {{ indicatorDescription }}
                    </div>
                    <div class="modal-footer">
                        <button type="button"
                                class="btn btn-primary"
                                data-bs-dismiss="modal">
                            OK
                        </button>
                    </div>
                </div>
            </div>
        </div>
</template>

<script>
export default {
    name: "ThemeIndicatorInfoModal",
    props: {
        indicatorDescription: String,
    },
}
</script>

<style scoped>

</style>
