<template>
    <div class="w-100 d-flex justify-content-end">
        <div
            class="btn btn-sm border border-dark tableButton me-1"
            id="tableButton"
            @mouseenter="hoverButton"
            @mouseleave="unHoverButton"
            @click="toggleClickState">
            <img class="pe-none" src="/images/icons/table-icon.svg" alt="table-button" width="20" height="20">
        </div>
        <div
            class="btn btn-sm border border-dark tableButton me-1"
            id="graphButton"
            @mouseenter="hoverButton"
            @mouseleave="unHoverButton"
            @click="toggleClickState">
            <img class="pe-none" src="/images/icons/graph-icon.svg" alt="graph-button" width="20" height="20">
        </div>
        <DatabaseIndicatorInfoModal
            :indicator-description="indicatorDescription"
        />
        <DatabaseInfoTextModal/>
    </div>
</template>

<script>
import DatabaseInfoTextModal from "./DatabaseInfoTextModal";
import DatabaseIndicatorInfoModal from "./DatabaseIndicatorInfoModal";

export default {
    name: "TableButtons",
    props: {
        indicatorDescription: String,
    },
    components: {
        DatabaseInfoTextModal,
        DatabaseIndicatorInfoModal,
    },
    data(){
        return {
            isClicked: false,
            selectedButton: 'tableButton'
        }
    },
    mounted() {
        document.querySelector('#tableButton').click()
        this.$emit('selectedTableButton', this.selectedButton)
    },
    watch: {
        selectedButton(newVal){
            this.$emit('selectedTableButton', newVal)
        }
    },
    methods: {
        hoverButton(e){
            e.target.classList.add('hovered')
        },
        unHoverButton(e){
            e.target.classList.remove('hovered')
        },
        toggleClickState(e){
            const buttons = document.querySelectorAll('.tableButton')
            buttons.forEach(button => button.classList.remove('clicked'))
            e.target.classList.add('clicked')
            if(e.target.id !== 'infoButton') {
                this.selectedButton = e.target.id
            }
        }
    }
}
</script>

<style scoped>

.hovered, .clicked {
    background-color: #01689b;
}

.hovered img, .clicked img {
    filter: invert(100%);
}
</style>
