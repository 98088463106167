<template>
    <div>
        <h5 class="text-primary fw-bold ps-3">Uitleg bij interactieve database</h5>
        <InfoText
            class="bg-lightblue p-3 border border-1 border-gray rounded"
        />
    </div>
</template>

<script>
import InfoText from "./InfoText";

export default {
    name: "InfoContainer",
    components: {
        InfoText,
    }
}
</script>

<style scoped>

.bg-lightblue {
    background-color: #F8FAFC;
}

.border-gray{
    border: 1px solid gray;
}

</style>
