<template>
    <p>
        <a :href=href target='_blank'>
            <slot/>
            <img class="ms-1" src="/images/icons/popup-link-icon.svg" alt="popup-link-icon" width="12" height="12">
        </a>
    </p>
</template>

<script>
export default {
    name: "NewsLink",
    props: {
        href: String,
    }
}
</script>

<style scoped>

</style>
