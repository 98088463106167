<template>
    <div class="d-flex flex-column justify-content-center align-items-center h-100">
        <div class="spinner-border text-primary" role="status">
        </div>
        <span class="fw-bolder">{{fallbackText}}...</span>
    </div>
</template>

<script>
export default {
    name: "LoadingSpinner",
    props: {
        fallbackText: String,
    },
}
</script>

<style scoped>

</style>
