<template>
    <ThemeLayout
        :theme-title=themeTitle
        :theme-results="getThemeResults(themeTitle)"
        :sub-theme="subTheme"
    />
</template>

<script>
import ThemeLayout from "../../components/layouts/ThemeLayout";

export default {
    name: "Theme",
    data() {
        return {
            themeResults: '',
            theme1: [
                'In het onderwijs is de tweede generatie buiten-Europees bezig met een inhaalslag, op de arbeidsmarkt ' +
                'blijven de achterstanden echter groot',
                'Op de arbeidsmarkt zien we de grootste achterstanden bij mensen met een achtergrond van buiten-Europa',
                'Blijvende toename achterstanden eerste generatie met Europese migratieachtergrond',
                'Belangrijk: De uitkomsten worden ook gedeeltelijk verklaard door de samenstelling van de groepen'
            ],
            theme2: [
                'De tweede generatie met een buiten-Europese achtergrond is bezig met een inhaalslag in het onderwijs, ' +
                'maar er zijn nog altijd uitdagingen',
                'Het is een blijvende uitdaging om nieuwkomers in de samenleving op het gemiddelde onderwijsniveau te ' +
                'krijgen. Dit vraagt om een voortdurende inspanning',
                'In 2021 doen burgers met een tweede generatie buiten-Europese migratieachtergrond vaker dan gemiddeld ' +
                'mbo-4, maar dit geldt niet voor alle sectorkamers/studierichtingen',
                'Er bestaat een grote diversiteit aan ontwikkelingen en trends als we verder inzoomen op groepen met ' +
                'verschillende herkomstlanden'
            ],
            theme3: [
                'Werknemers met een migratieachtergrond hebben een lagere baanzekerheid',
                'Werknemers met een migratieachtergrond krijgen het minst goed betaald',
                'De overgang tussen het onderwijs en de arbeidsmarkt levert vooral problemen op voor de tweede ' +
                'generatie mbo’ers met een buiten-Europese migratieachtergrond',
                'Het kan wel: De tweede generatie starters met een migratieachtergrond en een masterdiploma maken een ' +
                'goede start op de arbeidsmarkt'
            ],
            theme4: [
                'Burgers met een buiten-Europese achtergrond staan het vaakst langs de kant',
                'Bevestiging conjunctuurgevoeligheid buiten-Europese groep',
                'Tweede generatie met buiten-Europese achtergrond participeert meer dan de eerste, maar is wel kwetsbaar',
                'Ook enkele positieve trends waargenomen, zoals bij de arbeidsparticipatie'
            ],
        }
    },
    components: {
        ThemeLayout,
    },
    props: {
        themeTitle: String,
        subTheme: String,
    },
    methods: {
        getThemeResults(themeTitle) {
            switch (themeTitle) {
                case('Algemene, overkoepelende presentatie'):
                    return this.themeResults = this.theme1
                case('Onderwijsprestaties en kansen op de arbeidsmarkt tijdens en na studie'):
                    return this.themeResults = this.theme2
                case('Culturele diversiteit op het werk en verschillen in posities tussen groepen'):
                    return this.themeResults = this.theme3
                case('Algemene arbeidsmarktindicatoren en sociale zekerheid (arbeidstoeleiding)'):
                    return this.themeResults = this.theme4
            }
        }
    }
}
</script>
