<template>
    <div class="container-lg mt-4">
        <p class="col-lg-8 mb-4">
            De monitor gelijkwaardige kansen is ingedeeld op basis van de drie hoofdthema's van de Werkagenda VIA.
            Op deze pagina staan de belangrijkste deelresultaten per hoofdthema. Klik op een thema of deelresultaat
            voor meer achtergrond achter de bevindingen.
        </p>
        <div class="row mb-4">
            <ThemeCard
                :theme-title=theme1Title
                :theme-intro-text="introTextTheme1"
                img-src="/images/algemene-conclusies.jpg"
                :theme-results=resultsTheme1
                :is-horizontal="isHorizontal"
            />
        </div>
        <div class="row mb-3">
            <ThemeCard
                class="col-xxl-4 col-md-6 mb-4"
                :theme-title=theme2Title
                :theme-intro-text="introTextTheme2"
                img-src="/images/kans-eerste-baan-jongeren.jpg"
                :theme-results=resultsTheme2
            />
            <ThemeCard
                class="col-xxl-4 col-md-6 mb-4"
                :theme-title=theme3Title
                :theme-intro-text="introTextTheme3"
                img-src="/images/diversiteit-op-het-werk.png"
                :theme-results=resultsTheme3
            />
            <ThemeCard
                class="col-xxl-4 col-md-6 mb-4"
                :theme-title=theme4Title
                :theme-intro-text="introTextTheme4"
                img-src="/images/arbeidstoeleiding.jpg"
                :theme-results=resultsTheme4
            />
        </div>
    </div>
</template>

<script>
import ThemeCard from "../../components/themes/ThemeCard";

export default {
    name: "Themes",
    data() {
        return {
            resultsTheme1: [
                'In het onderwijs is de tweede generatie buiten-Europees bezig met een inhaalslag, op de arbeidsmarkt ' +
                'blijven de achterstanden echter groot',
                'Op de arbeidsmarkt zien we de grootste achterstanden bij mensen met een achtergrond van buiten-Europa',
                'Blijvende toename achterstanden eerste generatie met Europese migratieachtergrond',
                'Belangrijk: De uitkomsten worden ook gedeeltelijk verklaard door de samenstelling van de groepen'
            ],
            resultsTheme2: [
                'De tweede generatie met een buiten-Europese achtergrond is bezig met een inhaalslag in het onderwijs, ' +
                'maar er zijn nog altijd uitdagingen',
                'Het is een blijvende uitdaging om nieuwkomers in de samenleving op het gemiddelde onderwijsniveau te ' +
                'krijgen. Dit vraagt om een voortdurende inspanning',
                'In 2021 doen burgers met een tweede generatie buiten-Europese migratieachtergrond vaker dan gemiddeld ' +
                'mbo-4, maar dit geldt niet voor alle sectorkamers/studierichtingen',
                'Er bestaat een grote diversiteit aan ontwikkelingen en trends als we verder inzoomen op groepen met ' +
                'verschillende herkomstlanden'
            ],
            resultsTheme3: [
                'Werknemers met een migratieachtergrond hebben een lagere baanzekerheid',
                'Werknemers met een migratieachtergrond krijgen het minst goed betaald',
                'De overgang tussen het onderwijs en de arbeidsmarkt levert vooral problemen op voor de tweede ' +
                'generatie mbo’ers met een buiten-Europese migratieachtergrond',
                'Het kan wel: De tweede generatie starters met een migratieachtergrond en een masterdiploma maken een ' +
                'goede start op de arbeidsmarkt'
            ],
            resultsTheme4: [
                'Burgers met een buiten-Europese achtergrond staan het vaakst langs de kant',
                'Bevestiging conjunctuurgevoeligheid buiten-Europese groep',
                'Tweede generatie met buiten-Europese achtergrond participeert meer dan de eerste, maar is wel kwetsbaar',
                'Ook enkele positieve trends waargenomen, zoals bij de arbeidsparticipatie'
            ],
            introTextTheme1: "Deze conclusies laten de rode draden zien over de hoofdthema's heen",
            introTextTheme2: 'In hoeverre volgen burgers met een migratieachtergrond een opleiding die hoge ' +
                'kansen biedt op de arbeidsmarkt? En hebben ze, tijdens of na de studie, gelijkwaardige kansen ' +
                'op het vinden van een stage of baan?',
            introTextTheme3: 'Hebben burgers met een migratieachtergrond gelijkwaardige kansen op promotie? ' +
                'En hoe staat het met hun baanzekerheid en inkomen?',
            introTextTheme4: 'Hoe zijn burgers met een migratieachtergrond vertegenwoordigd in de ' +
                'werkloosheidsstatistieken, participatiestatistieken en in de sociale zekerheid? En ' +
                'hoeveel moeite ondervinden zij bij het vinden van een baan vanuit de sociale zekerheid?',
            isHorizontal: false,
        }
    },
    components: {
        ThemeCard,
    },
    mounted() {
        // Listen for window resize events
        window.addEventListener('resize', this.handleResize);
        // Initial check for the window width
        this.handleResize();
    },
    computed: {
        theme1Title() {
            return 'Algemene, overkoepelende presentatie'
        },
        theme2Title() {
            return 'Onderwijsprestaties en kansen \nop de arbeidsmarkt tijdens en na studie'
        },
        theme3Title() {
            return 'Culturele diversiteit op het werk \nen verschillen in posities tussen groepen'
        },
        theme4Title() {
            return 'Algemene arbeidsmarktindicatoren en \nsociale zekerheid (arbeidstoeleiding)'
        },
    },
    methods: {
        handleResize() {
            this.isHorizontal = window.innerWidth >= 992
        }
    }
}
</script>
