<template>
    <Chart
        :options="options"
        :loading="apiLoading"
        :is-bar-chart="isBarChart"
        height='500px'
    />
</template>

<script>
import Chart from "../../Chart";

export default {
    name: "DatabaseChart",
    props: {
        apiLoading: Boolean,
        apiResponseParams: Object,
        dataHeaders: Array,
        data: Array,
        indicatorName: String,
    },
    data() {
        return {
            options: {
                title: {
                    text: '',
                    show: false,
                },
                tooltip: {
                    trigger: 'axis'
                },
                legend: {
                    width: "80%",
                    bottom: "0%",
                    orient: "horizontal",
                    data: [],
                },
                grid: {
                    left: '3%',
                    right: '4%',
                    containLabel: true
                },
                toolbox: {
                    feature: {
                        saveAsImage: {
                            title: 'Download grafiek',
                            name: this.indicatorName,
                        }
                    }
                },
                xAxis: {
                    type: 'category',
                    data: [],
                    name: "Jaren",
                    axisLine: {
                        onZero: false
                    },
                    nameLocation: 'middle',
                    nameTextStyle: {
                        fontWeight: "bold",
                        fontSize: 14,
                        padding: [12, 0, 0, 0]
                    }
                },
                yAxis: {
                    type: 'value'
                },
                dataZoom: [],
                series: []
            },
            lineColors: {
                'Eerste generatie Europees': 'red',
                'Tweede generatie Europees': 'orange',
                'Eerste generatie buiten-Europees': 'blue',
                'Tweede generatie buiten-Europees': '#33B5B3',
                'Burgers zonder migratieachtergrond': '#7F7F7F',
                'Gemiddelde van de bevolking': 'black',
            }
        }
    },
    components: {
        Chart,
    },
    computed: {
        legendData() {
            return this.dataHeaders.filter(header => header !== 'Jaartal')
        },
        xAxisData() {
            let years = []
            for (let item of this.data) {
                years.push(item['Jaartal'])
            }
            return years
        },
        chartData() {
            let data = []
            for (let header of this.legendData) {
                data.push(
                    {
                        name: header,
                        type: this.isBarChart ? 'bar' : 'line',
                        color: this.colorizeLine(header),
                        connectNulls: true,
                        data: this.data.map(item => item[header])
                    }
                )
            }
            return data
        },
        isBarChart() {
            return Object.keys(this.data).length <= 4
        }
    },
    created() {
        this.setGraphOptions()
    },
    updated() {
        this.setGraphOptions()
    },
    methods: {
        setGraphOptions() {
            this.options.legend.data = this.legendData
            this.options.legend.type = this.graphOnlyHasSixSplitAndNoBreakdowns() ? 'plain' : 'scroll'
            this.options.grid.bottom = this.graphOnlyHasSixSplitAndNoBreakdowns() ? '15%' : '10%'
            this.options.xAxis.boundaryGap = this.isBarChart
            this.options.xAxis.data = this.xAxisData
            this.options.series = this.chartData
            if (this.graphOnlyHasSixSplitAndNoBreakdowns()) {
                this.determineGridBottomPadding()
                window.addEventListener('resize', this.determineGridBottomPadding)
            }
            if (this.isBarChart) {
                this.options.legend.bottom = 10
                this.options.grid.bottom = '20%'
                this.options.dataZoom = {
                    type: 'slider',
                    height: 30,
                    bottom: 45,
                }
            } else {
                this.options.grid.bottom = this.graphOnlyHasSixSplitAndNoBreakdowns() ? '15%' : '10%'
                this.options.legend.bottom = '0%'
                this.options.dataZoom = []
            }
        },
        colorizeLine(name) {
            for (let lineName of Object.keys(this.lineColors)) {
                if (lineName === name) {
                    return this.lineColors[lineName]
                }
            }
            return '';
        },
        graphOnlyHasSixSplitAndNoBreakdowns() {
            return this.apiResponseParams.selectedOrigins.length === 1
                && this.apiResponseParams.selectedOrigins.includes('6-deling')
                && this.apiResponseParams.selectedBreakdown === 'Iedereen'
        },
        determineGridBottomPadding() {
            let options = this.options
            if (window.matchMedia('(min-width: 1200px)').matches) {
                options.grid.bottom = '15%'
            } else if (window.matchMedia('(min-width: 900px) and (max-width: 1200px) ').matches) {
                options.grid.bottom = '20%'
            } else options.grid.bottom = '20%'
        }
    }
}
</script>
