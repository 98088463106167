<template>
    <div class="mb-3">
        <h5 class="text-primary fw-bold">{{ title }}</h5>
            <slot/>
    </div>
</template>

<script>
export default {
    name: "FilterMenuItem",
    props: {
        title: String,
    }
}
</script>

<style scoped>

</style>
