require('./bootstrap');

import { createApp, h } from 'vue'
import { createInertiaApp } from '@inertiajs/vue3'
import Layout from './components/layouts/AppLayout.vue'
import ImgBanner from "./components/ImgBanner";

createInertiaApp({
    resolve: name => {
        const page = require(`./Pages/${name}`).default
        if (page.layout === undefined && !name.startsWith('Home')) {
            page.layout = Layout
        }
        return page
    },
    setup({ el, App, props, plugin }) {
        createApp({ render: () => h(App, props) })
            .use(plugin)
            .component('ImgBanner', ImgBanner)
            .mount(el)
    },
})
