<template>
    <div>
        <p>Welkom bij de interactieve database.</p>
        <p>
            Hier kunt u zelf tabellen en grafieken over de indicatoren van de Monitor gelijkwaardige
            kansen samenstellen en downloaden. Volg hiervoor de onderstaande stappen en druk op toepassen.
        </p>
        <section>
            <p class="mb-0 fw-bold">Stap 1. Kies indicator</p>
            <p>
                Klik links op een themaonderwerp om één indicator te selecteren. Binnen het themaonderwerp
                "Arbeidstoeleiding" kan bijvoorbeeld de indicator "Werkloosheid" gekozen worden. Download
                <a href="/files/Overzicht indicatoren interactieve database.pdf" download>hier</a> een overzicht van
                alle indicatoren in de interactieve database.
            </p>
            <p>
                Bij enkele indicatoren verschijnen er aanvullende opties (stap 1a en/of stap 1b). Als u bijvoorbeeld
                kiest voor "Burger werkend in uurloonkwartiel in sector" dan selecteert u bij stap 1a het
                uurloonkwartiel
                ("0 - 25%") en bij stap 1b de sector ("onderwijs").
            </p>
        </section>
        <section>
            <p class="mb-0 fw-bold">Stap 2. Kies datatype</p>
            <p>Voor de indicatoren zijn er vier datatypen:</p>
            <ul>
                <li>
                    <span class="fst-italic">Waarde:</span> Bij werkloosheid wordt de waarde bijvoorbeeld uitgedrukt als
                    percentage.
                </li>
                <li>
                    <span class="fst-italic">Index:</span> Het indexcijfer met als basisjaar 2008.
                </li>
                <li>
                    <span class="fst-italic">Ongecorrigeerd verschil t.o.v. gemiddelde:</span> Verschil in werkloosheid
                    ten opzichte van het
                    gemiddelde de bevolking. Deze verschillen zijn <span class="fst-italic">niet</span> gecorrigeerd
                    voor sociaal-economische
                    persoonskenmerken zoals leeftijd, geslacht en opleidingsniveau.
                </li>
                <li>
                    <span class="fst-italic">Gecorrigeerd verschil t.o.v. gemiddelde:</span> Verschillen ten opzichte
                    van het gemiddelde de bevolking. Deze verschillen zijn wel gecorrigeerd voor sociaal-economische
                    persoonskenmerken zoals leeftijd, geslacht en opleidingsniveau. Zie onze
                    <DisclaimerModal>disclaimer</DisclaimerModal>
                    over deze gecorrigeerde verschillen ten aanzien van het gemiddelde.
                </li>
            </ul>
        </section>
        <section>
            <p class="mb-0 fw-bold">Stap 3. Kies migratieachtergronden</p>
            <p>
                Vervolgens selecteert u welke migratieachtergronden voor de indicator vergeleken zullen worden.
                Deze 6-deling bestaat uit de herkomstgroepen:
            </p>
            <ul>
                <li>Europa (1ste en 2de generatie)</li>
                <li>Buiten-Europa (1ste en 2de generatie)</li>
                <li>Zonder migratieachtergrond</li>
                <li>Gemiddelde van de bevolking</li>
            </ul>
            <p>
                Voor de (on)gecorrigeerde verschillen ten aanzien van het gemiddelde van de bevolking
                kunt u meer specifieke herkomstgroepen selecteren, zoals Turkije, Indonesië en Suriname.
            </p>
        </section>
        <section>
            <p class="mb-0 fw-bold">Stap 4. Kies verdere uitsplitsing</p>
            <p>
                Indien u bij een indicator de (on)gecorrigeerde verschillen ten aanzien van het gemiddelde heeft
                geselecteerd, dan kunt u ook één verdere uitsplitsing toevoegen, zoals geslacht, leeftijd, of
                opleidingsniveau. Het is dus niet mogelijk om meerdere verdere uitsplitsingen tegelijkertijd te kiezen.
                Ook zijn deze uitsplitsingen niet bij alle indicatoren beschikbaar. De (on)gecorrigeerde
                verschillen worden standaard gemeten ten opzicht van het gemiddelde van de bevolking.
                Als bij stap 3 echter een verdere uitsplitsing is geselecteerd (“geslacht”), dan wordt,
                zeg, een vrouw uit Afghanistan vergeleken met een gemiddelde Nederlandse vrouw.
            </p>
        </section>
        <section>
            <p class="mb-0 fw-bold">Toepassen</p>
            <p>
                Klik vervolgens op de knop
                <button class="btn btn-sm btn-primary disabled">Toepassen</button>
                (links onderin) om de tabel of
                grafiek met uw selectie te verversen. Mocht u alle filters willen resetten, dan kunt u klikken op
                <button class="btn btn-sm btn-grey disabled border-0">Reset filters</button>
                onder de knop "Toepassen".
            </p>
        </section>
    </div>
</template>

<script>
import DisclaimerModal from "../../DisclaimerModal"

export default {
    name: "InfoText",
    components: {DisclaimerModal}
}
</script>

<style scoped>
.btn-grey, .btn-grey:hover {
    background-color: lightgrey;
    font-weight: bold;
}
</style>
