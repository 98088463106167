<template>
    <!-- Button trigger modal -->
    <a role="button" href="" data-bs-toggle="modal" data-bs-target="#disclaimerModal" id="disclaimerAnchorTag">
        <slot/>
    </a>
    <!-- Modal -->
    <div class="modal fade"
         id="disclaimerModal"
         data-bs-backdrop="static"
         data-bs-keyboard="false"
         tabindex="-1"
         aria-labelledby="disclaimerModalLabel"
         aria-hidden="true"
    >
        <div class="modal-dialog modal-lg modal-dialog-centered modal-dialog-scrollable">
            <div class="modal-content">
                <div class="modal-header">
                    <h1 class="modal-title fs-5" id="disclaimerModalLabel">
                        Disclaimer over gecorrigeerde verschillende ten aanzien van het gemiddelde
                    </h1>
                </div>
                <div class="modal-body">
                    <p class="fst-italic">
                        De gecorrigeerde verschillen ten aanzien van het gemiddelde van de bevolking tonen welke
                        verschillen tussen groepen overblijven nadat we corrigeren voor andere kenmerken van personen
                        binnen de groep. We voeren deze correctie uit met behulp van een "evenredigheidsanalyse". Voor
                        de onderwijsindicatoren controleren wij hierbij voor het geslacht van de persoon en het totale
                        inkomen van de ouder(s) en waar mogelijk/zinvol leeftijd. We gebruiken het totale inkomen van de
                        ouder(s) als proxy voor opleidingsniveau, omdat het opleidingsniveau van ouders met een
                        migratieachtergrond relatief vaak onbekend is.
                    </p>
                    <p class="fst-italic">
                        Bij het gebruik van het totale inkomen van de ouder(s) moet een aantal kanttekeningen worden
                        geplaatst. Het inkomen van de ouders van burgers met een eerste generatie migratieachtergrond is
                        relatief vaak onbekend. Voor ouders van burgers met een tweede generatie migratieachtergrond kan
                        het inkomen daarnaast een te lage inschatting geven van het opleidingsniveau van de ouders en
                        leiden tot overcorrectie. Wanneer verschillen kleiner worden door te corrigeren voor het totale
                        inkomen van de ouder(s) betekent dit niet dat de verschillen er niet zijn, maar dat de
                        verschillen samenhangen met het totale inkomen van de ouder(s).
                    </p>
                    <p class="fst-italic">
                        Voor de indicatoren die gaan over de overgang van onderwijs naar de arbeidsmarkt controleren wij
                        in de evenredigheidsanalyse voor het geslacht van de persoon, het totale inkomen van de
                        ouder(s), leeftijd en studierichting en voor duur vinden van een baan na het diploma corrigeren
                        we voor opleidingsniveau. Bij de indicatoren die gaan over de arbeidsparticipatie, sociale
                        zekerheid en posities is in de evenredigheidsanalyse wordt gecontroleerd voor leeftijd,
                        geslacht, het totale inkomen van de ouder(s), de hoogst behaalde opleiding, sector van meest
                        recente baan, burgerlijke staat en het aantal kinderen.
                    </p>
                    <p class="fst-italic">
                        Als we zien dat bijvoorbeeld op de arbeidsmarkt na correctie voor achtergrondkenmerken,
                        waaronder onderwijsniveau, er geen (of kleine) verschillen resteren, dan betekent dit niet dat
                        de verschillen er niet zijn, maar dat ze komen door bijvoorbeeld een lager opleidingsniveau wat
                        samenhangt met migratieachtergrond. Met andere woorden: <span class="fw-bold">De verschillen in
                        het onderwijs werken door op de arbeidsmarkt</span>.
                    </p>
                </div>
                <div class="modal-footer">
                    <button type="button"
                            class="btn btn-primary"
                            data-bs-dismiss="modal">
                        OK
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "DisclaimerModal"
}
</script>
