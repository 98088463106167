<template>
        <div class="border-dark border-end border-5 px-0 mb-3">
            <ul class="mt-3 mb-0 bg-light list-unstyled">
                    <li class="ps-5 py-3 pe-4 text-secondary"
                        @click="highlightMenuItem"
                        v-for="(result,index) of themeResults"
                        :id="index === 0 ? 'defaultSubResult' : null"
                        :class="{ clicked : isClicked }"
                    > {{ result }}
                    </li>
            </ul>
        </div>
        <p class="fw-bold text-primary">Toelichting grafiek:</p>
        <div id="toelichting-grafiek" class="p-2" v-html="explanationGraph"/>
</template>

<script>
export default {
    name: "ThemeMenu",
    data(){
        return {
            isClicked: false,
            selectedSubResult: ''
        }
    },
    emits: [
      'selectedSubResult',
    ],
    props: {
        explanationGraph: String,
        themeResults: Array,
        subTheme: String,
        getWebData: Function,
    },
    async mounted() {
        if(this.subTheme !== null) {
            let subThemeItems = document.querySelectorAll('li')
            subThemeItems.forEach(item => item.innerText === this.subTheme ? item.click() : '')
        } else {
            document.querySelector('#defaultSubResult').click()
        }
    },
    methods: {
        highlightMenuItem(e) {
            let subthemeItems = document.querySelectorAll('li')
            for (let item of subthemeItems) {
                item.classList.remove('clicked')
            }
            e.target.classList.add('clicked')
            this.selectedSubResult = e.target.innerText
            this.$emit('selectedSubResult', this.selectedSubResult)
            this.getWebData()
        },
    }
}
</script>

<style scoped>

#toelichting-grafiek {
    background-color: #CAE1E9;
}

li {
    cursor: pointer;
}

li:hover {
    background-color: #CAE1E9;
    border-left: 5px solid #01689B;
    font-weight: bold;
}

.clicked {
    background-color: #CAE1E9;
    border-left: 5px solid #01689B;
    font-weight: bold;
}

</style>
