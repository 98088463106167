<template>
    <select
        v-model="selectedIndicatorDetail"
        class="form-select"
        aria-label="Indicator detail"
        :disabled="shouldBeDisabled"
        @change="emitIndicatorDetail(selectedIndicatorDetail)"
    >
        <option value="" disabled>{{ firstSelectOption }}</option>
        <option
            v-for="indicatorDetail of indicatorDetailsToLoop"
            :value="indicatorDetail"
        >
            {{ indicatorDetail }}
        </option>
    </select>
</template>

<script>
import axios from 'axios'

export default {
    emits: [
        'selectedIndicatorDetail1a',
        'selectedIndicatorDetail1b',
        'selectedDatatype',
    ],
    name: "IndicatorsDetailDropdown",
    props: {
        step: String,
        selectedIndicator: String,
        selectedIndicatorDetails: Object,
        getFilteredIndicatorDetails: Function,
        resetFilters: Boolean,
        indicatorDetails: Array,
        themeIndicator: String,
        themeIndicatorDetail: String,
    },
    data() {
        return {
            selectedIndicatorDetail: '',
            filteredIndicatorDetails: [],
            filtered: false,
        }
    },
    computed: {
        firstSelectOption() {
            return this.selectedIndicator === '' ? 'Geen data' : 'Maak een keuze'
        },
        shouldBeDisabled() {
            return this.selectedIndicator === ''
        },
        indicatorDetailsToLoop() {
            return this.filtered ? this.filteredIndicatorDetails : this.indicatorDetails
        }
    },
    mounted() {
        if (this.themeIndicator !== '') {
            this.$nextTick(() => {
                this.selectedIndicatorDetail = this.themeIndicatorDetail
            })
        }
    },
    watch: {
        selectedIndicator() {
            // reset the dropdown to initial value
            this.resetIndicatorDetailDropdown()
        },
        selectedIndicatorDetails(newVal) {
            if (newVal) {
                this.filteredIndicatorDetails = this.getFilteredIndicatorDetails()
                this.filtered = this.filteredIndicatorDetails.length > 0
            }
        },
        resetFilters(newVal) {
            if (newVal === true) {
                this.resetIndicatorDetailDropdown()
            }
        }
    },
    methods: {
        emitIndicatorDetail(value) {
            if (this.step === '1a' || this.step === '1b') {
                this.$emit(`selectedIndicatorDetail${this.step}`, value)
            }
            this.$emit(`selectedDatatype`, value)
        },
        resetIndicatorDetailDropdown() {
            this.selectedIndicatorDetail = ''
            this.filtered = false

            this.emitIndicatorDetail(null)
        }
    }
}
</script>
