<template>
    <div class="accordion" id="indicatorsAccordion">
        <div class="accordion-item mb-2" v-for="(themeKey, index) of Object.keys(filterIndicators)">
            <h2 class="accordion-header" :id="`heading${index}`">
                <button class="accordion-button text-secondary fw-bold"
                        :class="{'collapsed' : !showAccordion(index , themeKey) }"
                        type="button" data-bs-toggle="collapse"
                        :data-bs-target="`#collapse${index}`"
                        :aria-controls="`collapse${index}`"
                        :aria-expanded="showAccordion(index, themeKey) ? 'true' : 'false'"
                        data-bs-parent="#indicatorsAccordion"
                >
                    {{ themeKey }}
                </button>
            </h2>
            <div :id="`collapse${index}`"
                 class="accordion-collapse collapse"
                 :class="{'show' : showAccordion(index,themeKey) }"
                 :aria-labelledby="`heading${index}`"
                 data-bs-parent="#indicatorsAccordion">
                <div class="accordion-body p-0">
                    <input class="form-control my-2" placeholder="Zoeken..." type="text"
                           v-model="searchBoxes[`search${index}`]">
                    <p class="text-center"
                       v-if="filteredIndicators(themeKey, index).length === 0">
                        Geen zoekresultaten
                    </p>
                    <div v-else class="list-group">
                        <button v-for="indicator of filteredIndicators(themeKey, index)"
                                class="list-group-item list-group-item-action py-0"
                                :id="indicator.indicator_category === this.themeIndicator ? 'themeIndicatorBtn' : null"
                                :value="indicator.indicator_category"
                                @click="highlightIndicator"
                        >
                            {{ indicator.indicator_category }}
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "IndicatorsAccordion",
    props: {
        filterIndicators: Object,
        themeIndicator: String,
        resetFilters: Boolean,
    },
    emits: ['selectedIndicator'],
    data() {
        return {
            searchBoxes: {
                search0: '',
                search1: '',
                search2: '',
            },
            selectedIndicator: '',
        }
    },
    watch: {
        searchBoxes: {
            handler() {
                this.unHighlightIndicators()
                const buttons = document.querySelectorAll('button')
                for (let button of buttons) {
                    if (button.innerText === this.selectedIndicator) {
                        button.classList.add('clicked')
                    }
                }
            },
            deep: true
        },
        resetFilters(newVal) {
            if (newVal === true) {
                this.resetIndicatorAccordion()
            }
        }
    },
    mounted() {
        if (this.themeIndicator !== '') {
            let target = document.querySelector('#themeIndicatorBtn')
            target.click()
            target.parentNode.scrollTop = target.offsetTop - target.parentNode.offsetTop
        }
    },
    methods: {
        highlightIndicator(e) {
            this.unHighlightIndicators()

            e.target.classList.add('clicked')
            this.selectedIndicator = e.target.value
            this.$emit('selectedIndicator', this.selectedIndicator)
        },
        unHighlightIndicators() {
            const indicators = document.querySelectorAll('button')
            indicators.forEach(indicator => indicator.classList.remove('clicked'))
        },
        filteredIndicators(themeKey, index) {
            return this.filterIndicators[themeKey]
                .filter(indicator => indicator.indicator_category.toLowerCase()
                    .includes(this.searchBoxes[`search${index}`].toLowerCase()))
        },
        showAccordion(index, themeKey) {
            if (index === 0 && this.themeIndicator === '') {
                return true
            } else return !!this.filterIndicators[themeKey]
                .some(element => element.indicator_category === this.themeIndicator);
        },
        resetIndicatorAccordion() {
            this.unHighlightIndicators()
            this.selectedIndicator = ''
            this.$emit('selectedIndicator', this.selectedIndicator)
        }
    }
}
</script>

<style scoped>

.accordion-button {
    padding-top: 10px;
    padding-bottom: 10px;
    font-size: 15px;
}

.clicked {
    background-color: #CAE1E9;
}

.list-group {
    max-height: 200px;
    overflow-y: scroll;
}

</style>
