<template>
    <div class="d-flex d-none d-lg-block">
        <img role="button" src="/images/icons/enlarge.svg" alt="Enlarge-button" height="20" width="20"
             data-bs-toggle="modal" data-bs-target="#graphModal" id="enlargeButton">
    </div>

    <!-- Modal -->
    <div class="modal fade" id="graphModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered modal-xl">
            <div class="modal-content">
                <div class="modal-header border-0">
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-body d-flex align-items-center">
                    <ThemeChart
                        :data="apiData"
                        :data-headers="dataHeaders"
                        :indicator-name="indicatorName"
                    />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import ThemeChart from "./ThemeChart";

export default {
    name: "GraphModal",
    props: {
        apiData: Array,
        dataHeaders: Array,
        indicatorName: String,
    },
    components: {
        ThemeChart
    }
}
</script>

<style scoped>

</style>
