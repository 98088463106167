<template>
    <div class="container-lg mt-4 mb-2">
        <h3 class="text-center fw-bold">{{ themeTitle }}</h3>
        <div class="row">
            <div class="col-lg-4 mb-3 mb-lg-0">
                <ThemeMenu
                    @selected-sub-result="onSelectedSubResult"
                    :explanation-graph="explanationGraph"
                    :theme-results="themeResults"
                    :sub-theme="subTheme"
                    :get-web-data="getWebData"
                />
            </div>
            <div class="col-lg-8">
                <div
                    v-if="!shouldHide"
                    class="d-flex align-items-center justify-content-end mb-1"
                >
                    <ThemeIndicatorInfoModal
                        :indicator-description="indicatorDescription"
                    />
                    <GraphModal
                        :api-data="apiData"
                        :data-headers="uniqueResearchDataHeaders"
                        :indicator-name="indicatorName"
                    />
                </div>
                <h4 v-if="!shouldHide"
                    class="text-primary fw-bold">
                    Indicator:
                    <span class="fw-lighter text-secondary">{{indicatorName}}</span>
                </h4>
                <ThemeChart
                    v-if="!shouldHide"
                    class="mt-2 mt-lg-3"
                    :api-loading="apiLoading"
                    :data="apiData"
                    :data-headers="uniqueResearchDataHeaders"
                    :indicator-name="indicatorName"
                />
                <img v-else
                     class="img-fluid mt-4"
                     src="/images/Monitor gelijkwaardige kansen - graph.png"
                     alt="grafiek"
                />
                <Link
                    v-if="!shouldHide"
                    class="btn btn-grey"
                    href="/interactievedatabase"
                    :data="{themeIndicator: this.indicator}"
                >Naar database
                </Link>
            </div>
        </div>
    </div>
</template>

<script>
import axios from "axios";
import { Link } from '@inertiajs/vue3'
import ThemeMenu from "../themes/ThemeMenu";
import ThemeChart from "../themes/ThemeChart";
import GraphModal from "../themes/GraphModal";
import ThemeIndicatorInfoModal from "../themes/ThemeIndicatorInfoModal";

export default {
    name: "ThemeLayout",
    data() {
        return {
            isClicked: false,
            apiLoading: null,
            selectedSubResult: '',
            apiData: [],
            apiResponseParams: {},
            explanationGraph: '',
            indicator: '',
            indicatorName: '',
            indicatorDescription: '',
        }
    },
    components: {
        Link,
        ThemeMenu,
        ThemeChart,
        GraphModal,
        ThemeIndicatorInfoModal,
    },
    props: {
        themeTitle: String,
        themeResults: Array,
        subTheme: String,
    },
    computed: {
        uniqueResearchDataHeaders(){
            let allKeys = []
            this.apiData.forEach(data => allKeys.push(...Object.keys(data)))
            return Array.from(new Set(allKeys))
        },
        shouldHide(){
            return this.selectedSubResult === 'Er bestaat een grote diversiteit aan ontwikkelingen en trends als we ' +
                'verder inzoomen op groepen met verschillende herkomstlanden' || this.selectedSubResult === 'Belangrijk:' +
                ' De uitkomsten worden ook gedeeltelijk verklaard door de samenstelling van de groepen'
        }
    },
    methods: {
        onSelectedSubResult(data){
            this.selectedSubResult = data
        },
        async getWebData(){
            this.apiLoading = true
            await axios.get('/api/webData', {params: {'selectedSubResult':this.selectedSubResult}})
            .then(response => {
                this.apiData = response.data.pivotData
                this.apiResponseParams = response.config.params
                this.explanationGraph = response.data.explanationGraph
                this.indicator = response.data.indicator
                this.indicatorName = response.data.indicatorName
                this.indicatorDescription = response.data.indicatorDescription
            } ).finally(() => {this.apiLoading = false})
        }
    }
}
</script>

<style scoped>

.btn-grey, .btn-grey:hover{
    background-color: lightgrey;
    font-weight: bold;
}

@media only screen and (max-width: 768px) {
    #enlargeButton {
        display: none;
    }
}

</style>
