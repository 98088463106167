<template>
    <!-- Button trigger modal -->
    <button type="button" class="btn btn-secondary w-100" data-bs-toggle="modal" data-bs-target="#originModal">
        <span class="fw-bold">Geselecteerd:</span>
        {{ originButtonTitle }}
    </button>

    <!-- Modal -->
    <div class="modal fade"
         id="originModal"
         data-bs-backdrop="static"
         data-bs-keyboard="false"
         tabindex="-1"
         aria-labelledby="originModalLabel"
         aria-hidden="true"
    >
        <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable">
            <div class="modal-content">
                <div class="modal-header">
                    <h1 class="modal-title fs-5" id="originModalLabel">Kies migratieachtergronden</h1>
                </div>
                <div class="modal-body px-5">
                    <div class="form-check">
                        <input class="form-check-input origin" type="checkbox" value="6-deling" id="zesDeling">
                        <label class="form-check-label dotted"
                               for="zesDeling"
                        >
                            6-deling
                        </label>
                        <img class="ms-2"
                             src="/images/icons/info-icon-circle.svg"
                             alt="info-icon"
                             width="20"
                             height="20"
                             data-bs-toggle="tooltip"
                             data-bs-placement="right"
                             data-bs-html="true"
                             :data-bs-title="zesDelingTooltip">
                    </div>
                    <div class="form-check">
                        <input class="form-check-input origin"
                               type="checkbox"
                               value="Overig"
                               id="Overig"
                               @click="toggleOtherOriginsCheckList"
                        >
                        <label class="form-check-label" for="Overig">
                            Overig
                        </label>
                        <img class="ms-2"
                             src="/images/icons/info-icon-circle.svg"
                             alt="info-icon"
                             width="20"
                             height="20"
                             data-bs-toggle="tooltip"
                             data-bs-placement="right"
                             data-bs-html="true"
                             :data-bs-title="otherOriginsTooltip">
                    </div>
                    <div class="ms-3" v-show="otherOriginsChecked">
                        <div class="form-check">
                            <input class="form-check-input"
                                   type="checkbox"
                                   id="selectOtherOriginsCheckList"
                                   :disabled="this.availableOtherOrigins.length === 0"
                                   @click="selectAllOtherOrigins">
                            <label class="form-check-label" for="selectOtherOriginsCheckList">
                                selecteer/deselecteer alles
                            </label>
                        </div>
                        <div class="form-check" v-for="otherOrigin of filterOtherOrigins">
                            <input class="form-check-input otherOrigin"
                                   type="checkbox"
                                   :value="otherOrigin"
                                   :id="otherOrigin"
                                   :disabled="checkOtherOrigin(otherOrigin)">
                            <label class="form-check-label" :for="otherOrigin">
                                {{ otherOrigin }}
                            </label>
                        </div>
                    </div>
                </div>
                <div class="modal-footer">
                    <button type="button"
                            class="btn btn-primary"
                            id="modalSaveBtn"
                            data-bs-dismiss="modal"
                            @click="updateOriginArray();updateOtherOriginsArray()">
                        Opslaan
                    </button>
                    <button type="button"
                            class="btn btn-secondary"
                            data-bs-dismiss="modal"
                    >
                        Annuleren
                    </button>
                </div>
            </div>
        </div>
    </div>

</template>

<script>
import {Tooltip} from 'bootstrap'
import axios from "axios";

export default {
    name: "OriginModal",
    props: {
        filterOtherOrigins: Array,
        themeIndicator: String,
        selectedIndicator: String,
        selectedIndicatorDetail1a: String,
        selectedIndicatorDetail1b: String,
        selectedDatatype: String,
        resetFilters: Boolean,
    },
    emits: ['selectedOrigins', 'selectedOtherOrigins'],
    data() {
        return {
            selectedOrigins: [],
            selectedOtherOrigins: [],
            otherOriginsChecked: false,
            availableOtherOrigins: '',
        }
    },
    computed: {
        zesDelingTooltip() {
            return '<div class="text-start text-nowrap">' +
                '<li>Eerste generatie Europees</li>' +
                '<li>Tweede generatie Europees</li>' +
                '<li>Eerste generatie buiten-Europees</li>' +
                '<li>Tweede generatie buiten-Europees</li>' +
                '<li>Burgers zonder migratieachtergrond</li>' +
                '<li>Gemiddelde van de bevolking</li>' +
                '</div>'
        },
        otherOriginsTooltip() {
            return '<div class="text-start">' +
                'Selecteer bij een indicator de (on)gecorrigeerde verschillen ten aanzien ' +
                'van het gemiddelde van de bevolking om overige migratieachtergronden te selecteren.' +
                '</div>'
        },
        originButtonTitle() {
            return this.selectedOrigins.length !== 0 && !this.resetFilters ? this.selectedOrigins.join(', ') : '-'
        }
    },
    watch: {
        selectedOrigins: {
            handler(newVal) {
                if (newVal.includes('Overig') && this.selectedOtherOrigins.length === 0) {
                    this.selectedOrigins.pop()
                }
            },
            deep: true
        },
        selectedIndicator() {
            this.resetOriginModal()
        },
        selectedIndicatorDetail1a: 'handleApiCall',
        selectedIndicatorDetail1b: 'handleApiCall',
        selectedDatatype: 'handleApiCall',
    },
    mounted() {
        // Enable the Bootstrap tooltip
        const tooltipTriggerList = document.querySelectorAll('[data-bs-toggle="tooltip"]')
        const tooltipList = [...tooltipTriggerList].map(tooltipTriggerEl => new Tooltip(tooltipTriggerEl))

        if (this.themeIndicator !== '') {
            document.querySelector('#zesDeling').click()
            document.querySelector('#modalSaveBtn').click()
        }
    },
    methods: {
        getAvailableOtherOrigins() {
            axios.get('/api/availableOtherOrigins', {
                params:
                    {
                        selectedIndicator: this.selectedIndicator,
                        selectedIndicatorDetail1a: this.selectedIndicatorDetail1a,
                        selectedIndicatorDetail1b: this.selectedIndicatorDetail1b,
                        selectedDatatype: this.selectedDatatype
                    }
            }).then(response =>
                this.availableOtherOrigins = response.data)
        },
        checkOtherOrigin(otherOrigin) {
            return !this.availableOtherOrigins.includes(otherOrigin)
                || this.availableOtherOrigins.length === 0
        },
        updateOriginArray() {
            this.selectedOrigins.splice(0) //reset the array every time the save button is clicked
            const checkboxes = document.querySelectorAll('.origin')
            checkboxes.forEach(checkbox => {
                if (checkbox.checked) {
                    this.selectedOrigins.push(checkbox.value)
                }
            })
            this.$emit('selectedOrigins', this.selectedOrigins)
        },
        updateOtherOriginsArray() {
            this.selectedOtherOrigins.splice(0) //reset the array every time the save button is clicked
            const checkboxes = document.querySelectorAll('.otherOrigin:checked')
            checkboxes.forEach(checkbox => {
                this.selectedOtherOrigins.push(checkbox.value)
            })
            this.$emit('selectedOtherOrigins', this.selectedOtherOrigins)
        },
        toggleOtherOriginsCheckList(e) {
            this.otherOriginsChecked = !!e.target.checked;
        },
        selectAllOtherOrigins(e) {
            const otherOrigins = document.querySelectorAll('.otherOrigin')
            if (e.target.checked) {
                otherOrigins.forEach(otherOrigin => otherOrigin.checked = true)
            } else
                otherOrigins.forEach(otherOrigin => otherOrigin.checked = false)
        },
        resetOriginModal() {
            document.querySelector('#zesDeling').checked = false
            document.querySelector('#Overig').checked = false
            this.otherOriginsChecked = false
            this.selectedOrigins.splice(0)
            this.selectedOtherOrigins.splice(0)
            this.$emit('selectedOrigins', this.selectedOrigins)
            this.$emit('selectedOtherOrigins', this.selectedOtherOrigins)
        },
        handleApiCall() {
            // Check if all required properties have values
            if (this.selectedIndicator && this.selectedDatatype) {
                // Trigger the API call
                this.getAvailableOtherOrigins()
            }
        }
    }
}
</script>
