<template>
    <DatabaseLayout
        @query-params="onQueryParams"
        :filter-function="getResearchData.bind(this, queryParams)"
        :filter-breakdowns="breakdowns"
        :filter-other-origins="otherOrigins"
        :filter-indicators="indicators"
        :indicator-description="indicatorDescription"
        :indicator-name="indicatorName"
        :theme-indicator="themeIndicator"
        :theme-indicator-detail-1a="themeIndicatorDetail1a"
        :theme-indicator-detail-1b="themeIndicatorDetail1b"
        :theme-data-type="themeDataType"
        :research-data-headers="researchDataHeaders"
        :research-data="researchData"
        :research-data-api-loading="apiLoading"
        :response-params-research-data-api="apiResponseParams"
    />
</template>

<script>
import DatabaseLayout from "../components/layouts/DatabaseLayout";
import axios from "axios";

export default {
    name: "Database",
    props: {
        'themeIndicator': String,
        'themeIndicatorName': String,
        'themeIndicatorDetail1a': String,
        'themeIndicatorDetail1b': String,
        'themeDataType': String,
        'breakdowns': Array,
        'otherOrigins': Array,
        'indicators': Object,
    },
    data(){
        return {
            queryParams: '',
            apiData: '',
            apiResponseParams: {},
            researchDataHeaders: [],
            researchData: [],
            apiLoading: null,
            indicatorDescription: '',
            indicatorName: '',
        }
    },
    components: {
        DatabaseLayout,
    },
    computed: {
        uniqueResearchDataHeaders(){
            let allKeys = []
            this.apiData.forEach(data => allKeys.push(...Object.keys(data)))
            return Array.from(new Set(allKeys))
        },
        queryParamsTheme(){
            return {
                params: {
                    selectedIndicator: this.themeIndicator,
                    selectedIndicatorDetail1a: this.themeIndicatorDetail1a,
                    selectedIndicatorDetail1b: this.themeIndicatorDetail1b,
                    selectedDatatype: this.themeDataType,
                    selectedOrigins: ['6-deling'],
                    selectedBreakdown: 'Iedereen'
                }
            }
        }
    },
    mounted(){
        if(this.themeIndicator !== '') {
            this.getResearchData(this.queryParamsTheme)
        }
    },
    methods:{
        onQueryParams(data){
            this.queryParams = data
        },
        async getResearchData(queryParams){
            this.apiLoading = true
            await axios.get('/api/researchData', queryParams).then(response => {
                if(response.data.length !== 0){
                    this.apiData = response.data.researchData
                    this.apiResponseParams = response.config.params
                    this.indicatorDescription = response.data.indicatorDescription
                    this.indicatorName = response.data.indicatorName
                    this.researchDataHeaders= this.uniqueResearchDataHeaders
                    this.researchData = this.apiData
                } else {
                    this.apiData = null
                    this.researchDataHeaders = null
                    this.researchData = null
                }
            }).finally(() => {
                this.apiLoading = false
                window.scrollTo({top: 0, behavior: 'smooth'});
            })
        }
    }
}
</script>

<style scoped>

</style>
