<template>
    <div>
        <div class="ps-0 col-6 offset-6">
            <img src="/images/rijksoverheid-logo.png" alt="" width="155" height="100">
        </div>

        <nav class="navbar navbar-dark navbar-expand-xl bg-primary mt-4 py-4">
            <div class="container-lg">
                <span class="navbar-brand text-white mb-0 h1 fs-4 text-wrap">
                    Monitor gelijkwaardige kansen en evenredige posities
                </span>

                <button class="navbar-toggler" type="button" data-bs-toggle="collapse"
                        data-bs-target="#navbarNavAltMarkup" aria-controls="navbarNavAltMarkup" aria-expanded="false"
                        aria-label="Toggle navigation">
                    <span class="navbar-toggler-icon"></span>
                </button>
                <div class="collapse navbar-collapse" id="navbarNavAltMarkup">
                    <div class="navbar-nav ms-auto">
                        <NavLink href="/" :active="$page.component === 'Home'">Home</NavLink>
                        <NavLink href="/overzichtconclusies" :active="$page.url.startsWith('/overzichtconclusies')">Overzicht conclusies</NavLink>
                        <NavLink href="/interactievedatabase" :active="$page.component === 'InteractiveDatabase'">Interactieve database</NavLink>
                        <NavLink href="/overdemonitor" :active="$page.component === 'About'">Over de monitor</NavLink>
                    </div>
                </div>
            </div>
        </nav>
    </div>

    <transition name="page" mode="out-in" appear>
        <slot/>
    </transition>

</template>

<script>
import NavLink from "../NavLink";

export default {
    props: {
        imgSrc: String,
    },
    components: {
        NavLink,
    },
    mounted() {
        console.log('Component mounted.')
    }
}
</script>

<style scoped>

#img-banner {
    object-fit: cover;
    width: 100%;
    min-height: 100px;
    max-height: 320px;
}

.page-enter-active,
.page-leave-active {
    transition: all .2s;
}

.page-enter,
.page-leave-active {
    opacity: 0;
}
</style>
