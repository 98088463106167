<template>
    <div class="container-lg mt-4">
        <div class="row">
            <div class="col-3">
                <FilterMenu
                    @selected-indicator="onSelectedIndicator"
                    @selected-indicator-detail1a="onSelectedIndicatorDetail1a"
                    @selected-indicator-detail1b="onSelectedIndicatorDetail1b"
                    @selected-datatype="onSelectedDatatype"
                    @selected-origins="onSelectedOrigins"
                    @selected-other-origins="onSelectedOtherOrigins"
                    @selected-breakdown="onSelectedBreakdown"
                    :filter-table="filterFunction"
                    :filter-breakdowns="filterBreakdowns"
                    :filter-other-origins="filterOtherOrigins"
                    :filter-indicators="filterIndicators"
                    :theme-indicator="themeIndicator"
                    :theme-indicator-detail-1a="themeIndicatorDetail1a"
                    :theme-indicator-detail-1b="themeIndicatorDetail1b"
                    :theme-data-type="themeDataType"
                    :query-params="queryParamsAvailableBreakdownsAPI"
                />
            </div>
            <div class="col-9">
                <ResultPanel
                    :research-data-headers="researchDataHeaders"
                    :research-data="researchData"
                    :api-response-params="responseParamsResearchDataApi"
                    :research-data-api-loading="researchDataApiLoading"
                    :theme-indicator="themeIndicator"
                    :indicator-description="indicatorDescription"
                    :indicator-name="indicatorName"
                />
            </div>
        </div>
    </div>
</template>

<script>
import FilterMenu from "../database/FilterMenu";
import ResultPanel from "../database/ResultPanel";

export default {
    name: "DatabaseLayout",
    props:{
      filterFunction: Function,
      filterBreakdowns: Array,
      filterOtherOrigins: Array,
      filterIndicators: Object,
      indicatorDescription: String,
      indicatorName: String,
      themeIndicator: String,
      themeIndicatorDetail1a: String,
      themeIndicatorDetail1b: String,
      themeDataType: String,
      researchDataHeaders: Array,
      researchData: Array,
      researchDataApiLoading: Boolean,
      responseParamsResearchDataApi: Object,
    },
    components: {
        FilterMenu,
        ResultPanel,
    },
    data(){
        return {
            selectedIndicator: '',
            selectedIndicatorDetail1a: '',
            selectedIndicatorDetail1b: '',
            selectedDatatype: '',
            selectedOrigins: [],
            selectedOtherOrigins: [],
            selectedBreakdown: '',
        }
    },
    computed: {
        queryParamsResearchDataApi(){
            return {
                params: {
                    selectedIndicator: this.selectedIndicator,
                    selectedIndicatorDetail1a: this.selectedIndicatorDetail1a,
                    selectedIndicatorDetail1b: this.selectedIndicatorDetail1b,
                    selectedDatatype: this.selectedDatatype,
                    selectedOrigins: this.selectedOrigins,
                    selectedOtherOrigins: this.selectedOtherOrigins,
                    selectedBreakdown: this.selectedBreakdown
                }
            }
        },
        queryParamsAvailableBreakdownsAPI(){
            return {
                params: {
                    selectedIndicator: this.selectedIndicator,
                    selectedIndicatorDetail1a: this.selectedIndicatorDetail1a || this.themeIndicatorDetail1a,
                    selectedIndicatorDetail1b: this.selectedIndicatorDetail1b || this.themeIndicatorDetail1b,
                    selectedDatatype: this.selectedDatatype || this.themeDataType,
                    selectedOrigins: this.selectedOrigins,
                    selectedOtherOrigins: this.selectedOtherOrigins,
                }
            }
        }
    },
    watch: {
      queryParamsResearchDataApi(newVal){
              this.$emit('queryParams', newVal)
          },
    },
    mounted() {
        this.$emit('queryParams', this.queryParamsResearchDataApi)
    },
    methods: {
        onSelectedIndicator(data){
            this.selectedIndicator = data
        },
        onSelectedIndicatorDetail1a(data){
            this.selectedIndicatorDetail1a = data
            this.$emit('selectedIndicatorDetail1a', data)
        },
        onSelectedIndicatorDetail1b(data){
            this.selectedIndicatorDetail1b = data
            this.$emit('selectedIndicatorDetail1b', data)
        },
        onSelectedDatatype(data){
            this.selectedDatatype = data
            this.$emit('selectedDatatype', data)
        },
        onSelectedOrigins(data){
            this.selectedOrigins = data
        },
        onSelectedOtherOrigins(data){
            this.selectedOtherOrigins = data
        },
        onSelectedBreakdown(data){
            this.selectedBreakdown = data
        }
    }
}
</script>
