<template>
    <LoadingSpinner
        v-if="researchDataApiLoading && selectedTableButton === 'tableButton'"
        fallback-text="Gegevensset wordt geladen"
    />
    <InfoContainer
        v-if="showInfoContainer"
        class="ms-4 col-10"
    />
    <DataSummary
        v-show="researchData.length > 0"
        :api-response-params="apiResponseParams"
        :indicator-name="indicatorName"
    />
    <TableButtons
        v-if="researchData.length > 0"
        class="pb-3"
        @selected-table-button="onSelectedTableButton"
        :indicator-description="indicatorDescription"
    />
    <Table
        v-show="selectedTableButton === 'tableButton'"
        :headers="researchDataHeaders"
        :data="researchData"
        :api-response-params="apiResponseParams"
        :api-response-received="apiResponseReceived"
        :indicator-name="indicatorName"
    />
    <DatabaseChart
        v-if="selectedTableButton === 'graphButton'"
        :api-loading="researchDataApiLoading"
        :api-response-params="apiResponseParams"
        :data-headers="researchDataHeaders"
        :data="researchData"
        :indicator-name="indicatorName"
    />
</template>

<script>
import TableButtons from "./resultpanel/TableButtons";
import Table from "./resultpanel/Table";
import DatabaseChart from "./resultpanel/DatabaseChart";
import InfoContainer from "./resultpanel/InfoContainer";
import LoadingSpinner from "../LoadingSpinner";
import DataSummary from "./resultpanel/DataSummary";

export default {
    name: "ResultPanel",
    props: {
        researchDataHeaders: Array,
        researchData: Array,
        researchDataApiLoading: Boolean,
        themeIndicator: String,
        apiResponseParams: Object,
        indicatorDescription: String,
        indicatorName: String,
    },
    data() {
        return {
            selectedTableButton: 'tableButton',
            apiResponseReceived: false,
        }
    },
    computed: {
        showInfoContainer() {
            return !this.apiResponseReceived && this.researchData.length === 0 && this.themeIndicator === ''
        }
    },
    watch: {
        apiResponseParams() {
            this.apiResponseReceived = true
        }
    },
    components: {
        TableButtons,
        Table,
        DatabaseChart,
        InfoContainer,
        LoadingSpinner,
        DataSummary,
    },
    methods: {
        onSelectedTableButton(data) {
            this.selectedTableButton = data
        }
    }
}
</script>
