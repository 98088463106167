<template>
    <FilterMenuItem title="Stap 1: Kies indicator">
        <indicatorsAccordion
            @selected-indicator="onSelectedIndicator"
            :filter-indicators="filterIndicators"
            :theme-indicator="themeIndicator"
            :reset-filters="resetFilters"
        />
    </FilterMenuItem>

    <!-- Optional options for step 1 -->
    <FilterMenuItem
        v-if="!hideIndicatorDetailsDropdown('1a')"
        title="Stap 1a: Kies extra optie"
    >
        <IndicatorsDetailDropdown
            step="1a"
            :indicator-details="getIndicatorDetailsForStep('1a')"
            :selected-indicator="selectedIndicator"
            :selected-indicator-details="selectedIndicatorDetails"
            :theme-indicator="themeIndicator"
            :theme-indicator-detail="themeIndicatorDetail1a"
            :get-filtered-indicator-details="() => getFilteredIndicatorsDetailsForStep('1a')"
            :reset-filters="resetFilters"
            @selected-indicator-detail1a="onSelectedIndicatorDetail1a"
        />
    </FilterMenuItem>
    <FilterMenuItem
        v-if="!hideIndicatorDetailsDropdown('1b')"
        title="Stap 1b: Kies extra optie"
    >
        <IndicatorsDetailDropdown
            step="1b"
            :indicator-details="getIndicatorDetailsForStep('1b')"
            :selected-indicator="selectedIndicator"
            :selected-indicator-details="selectedIndicatorDetails"
            :theme-indicator="themeIndicator"
            :theme-indicator-detail="themeIndicatorDetail1b"
            :get-filtered-indicator-details="() => getFilteredIndicatorsDetailsForStep('1b')"
            @selected-indicator-detail1b="onSelectedIndicatorDetail1b"
        />
    </FilterMenuItem>

    <FilterMenuItem title="Stap 2: Kies datatype">
        <IndicatorsDetailDropdown
            step="2"
            :indicator-details="getIndicatorDetailsForStep('2')"
            :selected-indicator-details="selectedIndicatorDetails"
            :get-filtered-indicator-details="() => getFilteredIndicatorsDetailsForStep('2')"
            :selected-indicator="selectedIndicator"
            :theme-indicator="themeIndicator"
            :theme-indicator-detail="themeDataType"
            @selected-datatype="onSelectedDatatype"
        />
    </FilterMenuItem>

    <FilterMenuItem title="Stap 3: Kies migratieachtergronden">
        <OriginModal
            ref="originModal"
            @selected-origins="onSelectedOrigins"
            @selected-other-origins="onSelectedOtherOrigins"
            :filter-other-origins="filterOtherOrigins"
            :theme-indicator="themeIndicator"
            :selected-indicator="selectedIndicator"
            :selected-indicator-detail1a="selectedIndicatorDetail1a"
            :selected-indicator-detail1b="selectedIndicatorDetail1b"
            :selected-datatype="selectedDatatype"
            :reset-filters="resetFilters"
        />
    </FilterMenuItem>
    <FilterMenuItem title="Stap 4: Kies verdere uitsplitsing">
        <BreakdownRadioList
            @selected-breakdown="onSelectedBreakdown"
            :filter-breakdowns="filterBreakdowns"
            :theme-indicator="themeIndicator"
            :query-params="queryParams"
            :reset-filters="resetFilters"
        />
    </FilterMenuItem>

    <div class="sticky-bottom">
        <ApplyButton
            :filter-table="filterTable"
            :selected-origins="selectedOrigins"
            :selected-indicator="selectedIndicator"
            :selected-breakdown="selectedBreakdown"
            :selected-data-type="selectedDatatype"
        />
        <button
            class="btn btn-grey w-100"
            @click="handleFiltersReset"
        >
            Reset filters
        </button>
    </div>
</template>

<script>
import FilterMenuItem from "./filtermenu/FilterMenuItem";
import IndicatorsAccordion from "./filtermenu/IndicatorsAccordion";
import OriginModal from "./filtermenu/OriginModal";
import BreakdownRadioList from "./filtermenu/BreakdownRadioList";
import ApplyButton from "./filtermenu/ApplyButton";
import IndicatorsDetailDropdown from "./filtermenu/IndicatorsDetailDropdown";
import axios from "axios";

export default {
    name: "FilterMenu",
    emits: ['selectedIndicator',
        'selectedIndicatorDetail1a',
        'selectedIndicatorDetail1b',
        'selectedDatatype',
        'selectedOrigins',
        'selectedOtherOrigins',
        'selectedBreakdown'],
    components: {
        FilterMenuItem,
        IndicatorsAccordion,
        OriginModal,
        BreakdownRadioList,
        ApplyButton,
        IndicatorsDetailDropdown,
    },
    props: {
        filterTable: Function,
        filterBreakdowns: Array,
        filterOtherOrigins: Array,
        filterIndicators: Object,
        themeIndicator: String,
        themeIndicatorDetail1a: String,
        themeIndicatorDetail1b: String,
        themeDataType: String,
        queryParams: Object,
    },
    data() {
        return {
            selectedOrigins: [],
            selectedOtherOrigins: [],
            selectedBreakdown: '',
            selectedIndicator: '',
            selectedIndicatorDetail1a: '',
            selectedIndicatorDetail1b: '',
            selectedDatatype: '',
            indicatorDetails: [],
            ApiLoadedFirstTime: false,
            resetFilters: false,
            stepMapping: {
                '1a': 'indicator_detail_1',
                '1b': 'indicator_detail_2',
                '2': 'indicator_type',
            }
        }
    },
    computed: {
        selectedIndicatorDetails() {
            return {
                '1a': this.selectedIndicatorDetail1a,
                '1b': this.selectedIndicatorDetail1b,
                '2': this.selectedDatatype,
            }
        },
    },
    watch: {
        selectedIndicator(newVal) {
            this.getAllIndicatorDetails(newVal)
            this.setResetFiltersVariable(newVal)
        },
        selectedIndicatorDetail1a(newVal) {
            this.setResetFiltersVariable(newVal)
        },
        selectedIndicatorDetail1b(newVal) {
            this.setResetFiltersVariable(newVal)
        },
        selectedDataType(newVal) {
            this.setResetFiltersVariable(newVal)
        },
        selectedOrigins(newVal) {
            this.setResetFiltersVariable(newVal)
        },
        selectedOtherOrigins(newVal) {
            this.setResetFiltersVariable(newVal)
        }
    },
    methods: {
        onSelectedIndicator(data) {
            this.selectedIndicator = data
            this.$emit('selectedIndicator', data)
        },
        onSelectedIndicatorDetail1a(data) {
            this.selectedIndicatorDetail1a = data
            this.$emit('selectedIndicatorDetail1a', data)
        },
        onSelectedIndicatorDetail1b(data) {
            this.selectedIndicatorDetail1b = data
            this.$emit('selectedIndicatorDetail1b', data)
        },
        onSelectedDatatype(data) {
            this.selectedDatatype = data
            this.$emit('selectedDatatype', data)
        },
        onSelectedOrigins(data) {
            this.selectedOrigins = [...data]
            this.$emit('selectedOrigins', data)
        },
        onSelectedOtherOrigins(data) {
            this.selectedOtherOrigins = [...data]
            this.$emit('selectedOtherOrigins', data)
        },
        onSelectedBreakdown(data) {
            this.selectedBreakdown = data
            this.$emit('selectedBreakdown', data)
        },
        setResetFiltersVariable(data) {
            if (data !== '' || data !== null || (Array.isArray(data) && data.length === 0)) {
                this.resetFilters = false
            }
        },
        handleFiltersReset() {
            // Emit an event to the OriginModal component to trigger the reset
            this.$refs.originModal.resetOriginModal();

            // Set resetFilters after the next DOM update and empty indicatorDetails
            this.$nextTick(() => {
                this.resetFilters = true;
                this.indicatorDetails.splice(0)
            });
        },
        getAllIndicatorDetails(selectedIndicator) {
            axios.get('/api/availableIndicatorDetails', {
                params: {
                    'selectedIndicator': selectedIndicator
                }
            }).then(response => {
                this.indicatorDetails = response.data
            }).finally(() => this.ApiLoadedFirstTime = true)
        },
        getIndicatorDetailsForStep(step) {
            const key = this.stepMapping[step]

            // Return a new array with unique values using the Javascript Set function
            return Array.from(new Set(
                this.indicatorDetails
                    .filter(item => item[key])
                    .map(item => item[key])
            ))
        },
        getFilteredIndicatorsDetailsForStep(step) {
            const key = this.stepMapping[step] //'key = indicator_detail_1, indicator_detail_2 etc.'
            const filterConditions = []

            if (this.selectedIndicatorDetail1a !== '' && key !== 'indicator_detail_1') {
                filterConditions.push(item => item['indicator_detail_1'] === this.selectedIndicatorDetail1a)
            }

            if (this.selectedIndicatorDetail1b !== '' && key !== 'indicator_detail_2') {
                filterConditions.push(item => item['indicator_detail_2'] === this.selectedIndicatorDetail1b)
            }

            if (this.selectedDatatype !== '' && key !== 'indicator_type') {
                filterConditions.push(item => item['indicator_type'] === this.selectedDatatype)
            }

            let filteredItems = this.indicatorDetails.filter(item =>
                filterConditions.every(condition => condition(item))
            ).map(item => item[key])

            const orderDetailsResult = this.checkIndicatorDetailsOrder(step)
            if (orderDetailsResult) {
                filteredItems = orderDetailsResult
            }

            // Return a new array with unique values using the Javascript Set function
            return Array.from(new Set(filteredItems))

        },
        checkIndicatorDetailsOrder(step) {

            // Prevent step 1a being filtered by step 1b or step 2
            if (step === '1a' && (this.selectedIndicatorDetail1b !== '' || this.selectedDatatype !== '')) {
                return []
            }

            // Prevent step 1b being filtered by step 2
            if (step === '1b' && this.selectedDatatype !== '') {
                return []
            }
        },
        hideIndicatorDetailsDropdown(step) {
            return this.selectedIndicator !== '' &&
                this.getIndicatorDetailsForStep(step).length === 0
                && this.ApiLoadedFirstTime

        },
    }
}
</script>

<style scoped>
.sticky-bottom {
    bottom: 5px;
}

.btn-grey, .btn-grey:hover {
    background-color: lightgrey;
    font-weight: bold;
}
</style>
