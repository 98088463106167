<template>
    <div class="container-lg mt-4">
        <div class="col-lg-7">
            <section class="mb-5">
                <p class="fw-bold text-primary mb-0">Monitor gelijkwaardige kansen 2023</p>
                <p class="mb-2">
                    In opdracht van het ministerie van Sociale Zaken en Werkgelegenheid heeft
                    Significant Public in de afgelopen jaren uitgebreide analyses verricht over de arbeidsmarktpositie
                    van
                    Nederlanders met een migratieachtergrond. U kunt de volgende documenten downloaden:
                </p>
                <FilesOverview />
            </section>

            <section class="mb-5">
                <p class="fw-bold text-primary mb-0">Taskforce Werk & Integratie en de Werkagenda VIA</p>
                <p>
                    Met het programma VIA is de afgelopen kabinetsperiode in praktijkpilots onderzocht
                    wat effectieve methoden zijn om de arbeidsmarktkansen en –posities van mensen met een
                    migratieachtergrond te verbeteren. Het gaat bijvoorbeeld om pilots zoals het objectiveren
                    van de werving en selectie bij werkgevers, betere begeleiding van werkzoekenden uit de bijstand
                    en om leerwerktrajecten voor nieuwkomers. De pilots zijn nagenoeg afgerond en er wordt gewerkt
                    aan de opschaling. De opschaling vindt plaats door middel van de Werkagenda VIA.
                </p>
                <p>
                    De Werkagenda VIA is een gezamenlijk vervolg op het programma VIA en is opgesteld
                    met 20 partijen die verenigd zijn in de Taskforce VIA.
                </p>
                <p>
                    Het doel van de Werkagenda VIA is om de komende jaren met concrete activiteiten gezamenlijk
                    te komen tot structurele verbetering van de arbeidsmarktkansen en –posities van mensen met
                    een migratieachtergrond. Dit gebeurt langs drie thema’s: ‘meer (culturele) diversiteit op
                    het werk’, ‘meer kans op een eerste baan of stage’ en ‘arbeidstoeleiding’. De activiteiten
                    in deze thema’s bouwen voort op lessen uit het programma VIA en andere effectief gebleken aanpakken.
                </p>
                <p>
                    Een belangrijke uitgangspunt binnen de Taskforce Werk & integratie en de Werkagenda VIA is om elkaar
                    te inspireren en te motiveren in het bevorderen van een inclusieve arbeidsmarkt. Onderlinge
                    samenwerking
                    en structurele kennisdeling liggen hieraan ten grondslag.
                </p>
                <p>
                    Leden van de Taskforce zijn: ABU, AWVN, Divosa, FNV, G4, G40, MBO Raad, NBBU, ministerie van
                    OCW, Randstad, SAM, SBB, SER, UAF, UWV, Vereniging Hogescholen, VNG, VNO-NCW, VluchtelingenWerk
                    Nederland, ministerie van SZW.
                </p>
            </section>

            <section class="mb-5">
                <p class="fw-bold text-primary mb-0">Colofon</p>
                <p>
                    Significant heeft deze website ontwikkeld in opdracht van het ministerie van Sociale
                    Zaken en Werkgelegenheid. De data zijn gebaseerd op een analyse van CBS microdata die door
                    Significant is uitgevoerd.
                </p>
            </section>

            <section>
                <p class="fw-bold text-primary mb-0">FAQ</p>
                <ul class="list-unstyled">
                    <li class="mb-1">
                        <p class="fw-bold mb-0">Hoe vaak wordt deze monitor geupdate?</p>
                        <p class="fst-italic ps-3 mb-0">De updates zullen jaarlijks in het najaar volgen.</p>
                    </li>
                    <li class="mb-1">
                        <p class="fw-bold mb-0">Wat betekent de eerste en tweede generatie?</p>
                        <p class="fst-italic ps-3 mb-0">
                            Deze monitor volgt de groepsindeling van het CBS. Tot de eerste generatie
                            behoort volgens het CBS een persoon die in het buitenland is geboren en van wie ten minste
                            één ouder in het buitenland is geboren. Tot de tweede generatie behoort volgens CBS een
                            persoon die in Nederland is geboren en van wie ten minste één ouder in het buitenland
                            is geboren.
                        </p>
                    </li>
                    <li class="mb-1">
                        <p class="fw-bold mb-0">Wie zijn burgers met een migratieachtergrond?</p>
                        <p class="fst-italic ps-3 mb-0">
                            Iemand is een burger met een migratieachtergrond als ten minste
                            één ouder in het buitenland is geboren. Hierin volgen we de definitie van het CBS.
                        </p>
                    </li>
                    <li class="mb-1">
                        <p class="fw-bold mb-0">Wie zijn de burgers met een Europese migratieachtergrond?</p>
                        <p class="fst-italic ps-3 mb-0">
                            Iemand is een burger met een Europese migratieachtergrond als tenminste één ouder in een
                            Europees land is geboren. Hieronder vallen alle herkomstlanden uit het werelddeel Europa,
                            met uitzondering van Nederland.
                        </p>
                    </li>
                    <li class="mb-1">
                        <p class="fw-bold mb-0">Wie zijn de burgers met een buiten-Europese migratieachtergrond?</p>
                        <p class="fst-italic ps-3 mb-0">
                            Iemand is een burger met een buiten-Europese migratieachtergrond als tenminste één ouder
                            in een land is geboren dat niet onderdeel is van het werelddeel Europa. Dit geldt dus voor
                            alle landen in de wereld, behalve Europese landen.
                        </p>
                    </li>
                    <li class="mb-1">
                        <p class="fw-bold mb-0">
                            Waarom worden in deze monitor de groepen met migratieachtergrond weergegeven,
                            is dat niet stigmatiserend?
                        </p>
                        <p class="fst-italic ps-3 mb-0">
                            Uit onderzoek weten we dat burgers met een migratieachtergrond structureel lagere kansen
                            en posities op de arbeidsmarkt hebben dan burgers zonder migratieachtergrond. Om deze
                            ongelijkheid weg te nemen hebben 20 partijen die verenigd zijn in de Taskforce VIA een
                            “Werkagenda VIA” opgesteld. Het is van maatschappelijk belang om te monitoren of op termijn
                            de kansen en posities van burgers met migratieachtergrond verbeteren. Deze monitor moet
                            daarmee
                            dus juist bijdragen aan een verbetering van de kansengelijkheid.
                        </p>
                    </li>
                    <li class="mb-1">
                        <p class="fw-bold mb-0">
                            Kan ik op verzoek meer data ontvangen dan in de database op de monitor beschikbaar is?
                        </p>
                        <p class="fst-italic ps-3 mb-0">Nee, dat is niet mogelijk.</p>
                    </li>
                </ul>
            </section>
            <section>
                <p class="fw-bold text-primary mb-0">Contact</p>
                <p>Indien u een andere vraag, of opmerking, heeft, kunt u mailen naar
                    <a href="mailTo:monitorgelijkwaardigekansen@minszw.nl">monitorgelijkwaardigekansen@minszw.nl</a>
                </p>
            </section>
        </div>
    </div>
</template>

<script>
import FilesOverview from "../components/FilesOverview"

export default {
    name: "About",
    components: {FilesOverview},
}
</script>
