<template>
    <div>
        <ImgBanner src="/images/homepage-banner.jpg" height="320"></ImgBanner>

        <div class="container-lg mt-4">
            <div class="row">
                <div class="col-lg-7 mb-5">
                    <p class="fw-bold text-primary mb-0">Welkom</p>
                    <p class="mb-2">De "Monitor gelijkwaardige kansen en evenredige posities op de arbeidsmarkt,
                        ongeacht migratieachtergrond" houdt bij in hoeverre burgers met een migratieachtergrond dezelfde
                        kansen hebben op de arbeidsmarkt als burgers zonder migratieachtergrond.
                    </p>
                    <p class="mb-5">Klik op
                        <Link href="/overzichtconclusies">Overzicht conclusies</Link>
                        voor een totaaloverzicht van de resultaten. Vanuit dit overzicht kunt u zich
                        verder verdiepen in een bepaald thema. Klik op
                        <Link href="/interactievedatabase">Interactieve database</Link>
                        om zelf een tabel of grafiek van de data te maken.
                    </p>

                    <p class="fw-bold text-primary mb-0">Analyserapport 'Monitor gelijkwaardige kansen 2023'</p>
                    <p class="mb-2">In opdracht van het ministerie van Sociale Zaken en Werkgelegenheid heeft
                        Significant Public een uitgebreide analyse verricht over de arbeidsmarktpositie van Nederlanders
                        met een migratieachtergrond.
                    </p>
                    <p>U kunt het rapport
                        <a href="/files/Monitor gelijkwaardige kansen - Analyserapport 2023.pdf" download>hier</a>
                        downloaden.
                    </p>
                </div>
                <div class="col-lg-2 offset-lg-3">
                    <p class="fw-bold text-primary">Laatste nieuws</p>
                    <NewsLink
                        v-for="link of visibleLinks"
                        :href="link.href"
                        >
                        {{ link.title }}
                    </NewsLink>

                    <!-- Display the "More" link if there are more links to show -->
                    <a
                        v-if="!showMore"
                        class="text-decoration-none"
                        role="button"
                       @click="toggleMoreLinks">
                        Meer..
                    </a>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Layout from "../components/layouts/AppLayout";
import {Link} from '@inertiajs/vue3'
import ImgBanner from "../components/ImgBanner";
import NewsLink from "../components/NewsLink";

export default {
    name: "Home",
    components: {Link, ImgBanner, NewsLink},
    layout: Layout,
    data() {
        return {
            showMore: false,
            allLinks: [
                {
                    href: "https://www.rijksoverheid.nl/documenten/kamerstukken/2023/12/18/voortgangsbrief-programma-vo" +
                        "or-een-inclusieve-arbeidsmarkt",
                    title: "Voortgangsbrief Programma Voor een Inclusieve Arbeidsmarkt, 18-12-2023"
                },
                {
                    href: "https://www.rijksoverheid.nl/documenten/kamerstukken/2023/09/08/voortgang-en-monitoring-werk" +
                        "agenda-mbo-en-stagepact",
                    title: "Kamerbrief over voortgang en monitoring Werkagenda mbo en Stagepact, 08-09-2023"
                },
                {
                    href: "https://www.rijksoverheid.nl/actueel/nieuws/2023/07/11/szw-meer-statushouders-aan-het-werk",
                    title: "Meer statushouders aan het werk, 11-07-2023"
                },
                {
                    href: "https://www.rijksoverheid.nl/actueel/nieuws/2023/03/10/statushouders-sneller-aan-het-werk",
                    title: "'Statushouders sneller aan het werk', 09-03-2023"
                },
                {
                    href: "https://www.rijksoverheid.nl/documenten/kamerstukken/2023/02/14/aanbieding-werkagenda-en-stagepact-mbo",
                    title: "Kamerbrief bij Werkagenda en stagepact mbo, 14-02-2023"
                },
                {
                    href: "https://www.rijksoverheid.nl/documenten/kamerstukken/2022/11/16/programma-via-voortgangsbrief-2022",
                    title: "Kamerbrief over stand van zaken programma Verdere Integratie op de Arbeidsmarkt, 16-11-2022"
                }
            ]
        }
    },
    computed: {
        visibleLinks() {
            return this.showMore ? this.allLinks : this.allLinks.slice(0,3)
        }
    },
    methods: {
        toggleMoreLinks() {
            // Toggle the showMore property to display/hide additional links
            this.showMore = !this.showMore
        }
    }
}
</script>

<style scoped>

</style>
