window._ = require('lodash');

try {
    require('bootstrap');
} catch (e) {}

/**
 * We'll load the axios HTTP library which allows us to easily issue requests
 * to our Laravel back-end. This library automatically handles sending the
 * CSRF token as a header based on the value of the "XSRF" token cookie.
 */

window.axios = require('axios');

window.axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';

/**
 * Echo exposes an expressive API for subscribing to channels and listening
 * for events that are broadcast by Laravel. Echo and event broadcasting
 * allows your team to easily build robust real-time web applications.
 */

// import Echo from 'laravel-echo';

// window.Pusher = require('pusher-js');

// window.Echo = new Echo({
//     broadcaster: 'pusher',
//     key: process.env.MIX_PUSHER_APP_KEY,
//     cluster: process.env.MIX_PUSHER_APP_CLUSTER,
//     forceTLS: true
// });

/**
 * Checks for Bootstrap 5's modal overlay which is still activated when you browse back or forth to a page.
 * In case the previous or forth button of the webbrowser has been clicked, the modal overlay will be deleted
 */

addEventListener("popstate", (event) => {
    let modalBackDrop = document.querySelector('.modal-backdrop')
    if(modalBackDrop !== null) {
        modalBackDrop.remove()
        document.body.classList.remove('modal-open')
        document.body.removeAttribute('style')
    }
});
