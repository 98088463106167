<template>
    <div>
        <div class="row">
            <div class="col-md-4 col-6">
                <ul>
                    <li>Analyserapport:</li>
                </ul>
            </div>
            <div class="col-md-3 col-6">
                <a href="/files/Monitor gelijkwaardige kansen - Analyserapport 2023.pdf" download>2023</a>,
                <a href="/files/Monitor gelijkwaardige kansen - Analyserapport 2022.pdf" download>2022</a>
                en
                <a href="/files/Monitor gelijkwaardige kansen - Analyserapport 2021.pdf" download>2021</a>
            </div>
        </div>
        <div class="row">
            <div class="col-md-4 col-6">
                <ul>
                    <li>Technisch rapport:</li>
                </ul>
            </div>
            <div class="col-md-3 col-6">
                <a href="/files/Monitor gelijkwaardige kansen - Technisch rapport 2023.pdf" download>2023</a>,
                <a href="/files/Monitor gelijkwaardige kansen - Technisch rapport 2022.pdf" download>2022</a>
                en
                <a href="/files/Monitor gelijkwaardige kansen - Technisch rapport 2021.pdf" download>2021</a>
            </div>
        </div>
        <div class="row">
            <div class="col-md-4 col-6">
                <ul>
                    <li>Factsheet algemene conclusies:</li>
                </ul>
            </div>
            <div class="col-md-3 col-6">
                <a href="/files/Monitor gelijkwaardige kansen - Factsheet algemene conclusies 2023.pdf"
                   download>2023</a>
                en
                <a href="/files/Monitor gelijkwaardige kansen - Factsheet algemene conclusies 2022.pdf"
                   download>2022</a>
            </div>
        </div>
        <div class="row">
            <div class="col-md-4 col-6">
                <ul>
                    <li>Factsheet onderwijs:</li>
                </ul>
            </div>
            <div class="col-md-3 col-6">
                <a href="/files/Monitor gelijkwaardige kansen - Factsheet onderwijs 2023.pdf" download>2023</a>
            </div>
        </div>
        <div class="row">
            <div class="col-md-4 col-6">
                <ul>
                    <li>Factsheet culturele diversiteit:</li>
                </ul>
            </div>
            <div class="col-md-3 col-6">
                <a href="/files/Monitor gelijkwaardige kansen - Factsheet culturele diversiteit 2023.pdf"
                   download>2023</a>
                en
                <a href="/files/Monitor gelijkwaardige kansen - Factsheet culturele diversiteit 2022.pdf"
                   download>2022</a>
            </div>
        </div>
        <div class="row">
            <div class="col-md-4 col-6">
                <ul>
                    <li>Factsheet sociale zekerheid en arbeidstoeleiding:</li>
                </ul>
            </div>
            <div class="col-md-3 col-6">
                <a href="/files/Monitor gelijkwaardige kansen - Factsheet sociale zekerheid en arbeidstoeleiding 2023.pdf"
                   download>2023</a>
                en
                <a href="/files/Monitor gelijkwaardige kansen - Factsheet sociale zekerheid en arbeidstoeleiding 2022.pdf"
                   download>2022</a>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "FilesOverview"
}
</script>

<style scoped>
ul {
    margin-bottom: 0;
}
</style>
