<template>
    <div class="w-100">
        <ECharts
            id="myChart"
            ref="myChart"
            class="mb-2"
            :manual-update="true"
            :loading="loading"
            :option="options"
            :init-options="initOptions"
            autoresize
        />
        <p class="fw-bold text-danger text-center mb-1">
            {{ graphNoteText }}
        </p>
    </div>
</template>

<script>
import ECharts from 'vue-echarts'
import 'echarts'

export default {
    name: "Chart",
    data() {
        return {
            initOptions: {
                width: this.width,
                height: this.height,
            },
        }
    },
    components: {
        ECharts,
    },
    props: {
        options: Object,
        width: String,
        height: String,
        loading: Boolean,
        isBarChart: Boolean,
    },
    computed: {
        graphNoteText() {
            return `U kunt ${this.chartElement} aan of uit zetten door op de items in de legenda te klikken!`
        },
        chartElement() {
            return this.isBarChart ? 'staven' : 'lijnen'
        }
    },
    watch: {
        options: {
            handler(newVal) {
                this.$refs.myChart.clear()
                this.$refs.myChart.setOption(newVal, {notMerge: true})
            },
            deep: true
        }
    }
}
</script>
