<template>
    <a
        tabindex="0"
        role="button"
        ref="applyButton"
        class="btn btn-primary mb-2 w-100"
        @click="validateFilters"
    >Toepassen</a>
</template>

<script>
import {Popover} from 'bootstrap'

export default {
    name: "ApplyButton",
    data() {
        return {
            popover: null,
            popoverOptions: {
                title: ' ',
                body: ' ',
                trigger: 'focus'
            },
            emptyFilters: []
        }
    },
    props: {
        filterTable: Function,
        selectedIndicator: String,
        selectedOrigins: Array,
        selectedBreakdown: String,
        selectedDataType: String,
    },
    mounted() {
        const applyButton = this.$refs.applyButton
        this.popover = new Popover(applyButton, this.popoverOptions)
        this.popover.disable()
    },
    computed: {
        popoverBody() {
            const formatter = new Intl.ListFormat('nl')
            let lastItem = this.emptyFilters.at(-1)
            let listStringEn = this.emptyFilters.join(' en ')
            if (this.emptyFilters.length > 2) {
                return `U heeft geen filters geselecteerd voor ${formatter.format(this.emptyFilters)}.`
            } else if (this.emptyFilters.length === 2) {
                return `U heeft geen filters geselecteerd voor ${listStringEn}.`
            }
            return `U heeft geen filters geselecteerd voor ${lastItem}.`
        }
    },
    methods: {
        validateFilters() {
            this.updateEmptyFiltersArray()
            if (this.emptyFilters.length > 0) {
                this.renderPopover()
            } else {
                this.filterTable()
            }
        },
        updateEmptyFiltersArray() {
            this.emptyFilters.splice(0) //reset the array
            if (this.selectedIndicator === '') {
                this.emptyFilters.push('stap 1')
            }
            if (this.selectedDataType === '') {
                this.emptyFilters.push('stap 2')
            }
            if (this.selectedOrigins.length === 0) {
                this.emptyFilters.push('stap 3')
            }
            if (this.selectedBreakdown === '') {
                this.emptyFilters.push('stap 4')
            }
        },
        renderPopover() {
            this.popover.enable()
            this.popover.setContent({
                '.popover-header': 'Waarschuwing',
                '.popover-body': this.popoverBody,
                '.popover-trigger': 'focus'
            })
            this.popover.show()
            this.popover.disable()
        }
    }
}
</script>
