<template>
    <div class="form-check" v-for="breakdown in filterBreakdowns">
        <input class="form-check-input"
               type="radio"
               :value="breakdown"
               :id="breakdown"
               v-model="selectedBreakdown"
               :disabled="checkBreakdown(breakdown)"
        >
        <label class="form-check-label" :for="breakdown">
            {{ breakdown === 'iedereen' ? 'Geen' : capitalizeFirstLetter(breakdown) }}
        </label>
    </div>
</template>

<script>
import axios from "axios";

export default {
    name: "BreakdownRadioList",
    props: {
        filterBreakdowns: Array,
        themeIndicator: String,
        queryParams: Object,
        resetFilters: Boolean,

    },
    emits: ['selectedBreakdown'],
    data() {
        return {
            selectedBreakdown: '',
            availableBreakdowns: '',
        }
    },
    watch: {
        selectedBreakdown(newValue) {
            this.$emit('selectedBreakdown', newValue)
        },
        queryParams: {
            handler(newVal) {
                if (newVal.params.selectedOrigins.length !== 0 && newVal.params.selectedIndicator !== '') {
                    this.getAvailableBreakdowns()
                } else {
                    if (this.availableBreakdowns.length !== 0) {
                        this.availableBreakdowns.splice(0) //reset
                    }
                }
                this.selectedBreakdown = 'Iedereen'
            },
            deep: true
        },
        resetFilters(newVal) {
            if (newVal === true) {
                this.resetBreakdownRadioList()
            }
        }
    },
    mounted() {
        if (this.themeIndicator !== '') {
            setTimeout(function () {
                document.querySelector('#iedereen').click()
            }, 1500)
        }
    },
    updated() {
        // If no breakdowns are available then unselect all breakdowns if any
        if (this.availableBreakdowns.length === 0) {
            this.selectedBreakdown = ''
        }
    },
    methods: {
        getAvailableBreakdowns() {
            axios.get('/api/availableBreakdowns', this.queryParams).then(response =>
                this.availableBreakdowns = response.data)
        },
        checkBreakdown(breakdown) {
            return !this.availableBreakdowns.includes(breakdown)
                || this.availableBreakdowns.length === 0
        },
        capitalizeFirstLetter(string) {
            return string.charAt(0).toUpperCase() + string.slice(1)
        },
        resetBreakdownRadioList() {
            this.selectedBreakdown = ''
            this.$emit('selectedBreakdown', this.selectedBreakdown)
        }
    }
}
</script>
