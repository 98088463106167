<template>
    <div class="d-flex flex-column justify-content-end">
        <Link
            class="text-decoration-none"
            :href="themePageUrl">
            <h5 id="themeTitle" class="text-primary fw-bolder text-center">{{ themeTitle }}</h5>
        </Link>
        <div class="card shadow border-end-0">
            <div :class="{'row g-0' : isHorizontal}">
                <div :class="{'col-md-4' : isHorizontal}">
                    <img :src="imgSrc"
                         class="card-img-top"
                         :class="imageClasses"
                         alt="">
                </div>
                <div :class="{'col-md-8' : isHorizontal}">
                    <div class="card-body border-dark border-end border-5 px-0"
                         :class="{'h-100' : isHorizontal}">
                        <p :id="!isHorizontal ? 'introText' : null" class="px-3">
                            {{ themeIntroText }}
                        </p>
                        <ul class="mt-3 mb-0 list-unstyled">
                            <Link class="text-decoration-none"
                                  :href='themePageUrl'
                                  v-for="result of themeResults"
                                  :data="{subthema: result}">
                                <li class="ps-5 pe-4 py-1 mb-3 text-primary"> {{ result }}</li>
                            </Link>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import {Link} from '@inertiajs/vue3'

export default {
    name: "ThemeCard",
    components: {
        Link,
    },
    props: {
        imgSrc: String,
        themeTitle: String,
        themeIntroText: String,
        themeResults: Array,
        isHorizontal: Boolean,
    },
    computed: {
        themePageUrl() {
            return `/overzichtconclusies/${this.themeTitle}`
        },
        imageClasses() {
            return {
                'verticalImage': !this.isHorizontal,
                'h-100': this.isHorizontal,
            };
        },
    },
    mounted() {
        this.handleResizeOfGeneralCard(); // Initial call to set styles based on the initial window size
        window.addEventListener('resize', this.handleResizeOfGeneralCard); // Add event listener for window resize
    },
    methods: {
        handleResizeOfGeneralCard() {
            // Only apply these styles to the general conclusions theme card
            if (window.matchMedia("(min-width: 768px) and (max-width: 992px)").matches) {
                /*
                 Using queryselector will automatically apply these styles to the first occurring introtext and card-body
                 in the DOM which corresponds with the general conclusions theme card
                 */
                document.querySelector('#introText').style.height = 'auto';
                document.querySelector('.card-body').style.height = 'auto';
            }
        }
    }
}
</script>

<style scoped>

li:hover {
    background-color: #CAE1E9;
    border-left: 5px solid #01689B;
    font-weight: bold;
}

.card-body {
    background: linear-gradient(to right, #D9D9D9, #FFFFFF);
    height: 570px;
}

@media screen and (max-width: 768px) {
    .card-body {
        height: auto;
    }
}

img, .verticalImage {
    object-fit: cover;
}

.verticalImage {
    height: 180px;
}

h5 {
    color: #333333;
}

#themeTitle {
    white-space: pre;
}

#introText {
    height: 115px;
}

@media screen and (max-width: 768px) {
    #introText {
        height: auto;
    }
}
</style>
