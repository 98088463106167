<template>
    <!-- Button trigger modal -->
    <div
        class="btn btn-sm border border-dark me-1"
        id="indicatorInfoButton"
        data-bs-toggle="modal"
        data-bs-target="#indicatorInfoModal">
        <img class="pe-none" src="/images/icons/info-icon-circle-black.svg" alt="indicator-info-button" width="20" height="20">
    </div>
    <!-- Modal -->
    <div class="modal fade"
         id="indicatorInfoModal"
         data-bs-backdrop="static"
         data-bs-keyboard="false"
         tabindex="-1"
         aria-labelledby="indicatorInfoModalLabel"
         aria-hidden="true"
    >
        <div class="modal-dialog modal-lg modal-dialog-centered modal-dialog-scrollable">
            <div class="modal-content">
                <div class="modal-header">
                    <h1 class="modal-title fs-5" id="indicatorInfoModalLabel">Informatie over deze indicator</h1>
                </div>
                <div class="modal-body">
                    {{indicatorDescription}}
                </div>
                <div class="modal-footer">
                    <button type="button"
                            class="btn btn-primary"
                            data-bs-dismiss="modal">
                        OK
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "DatabaseIndicatorInfoModal",
    props: {
        indicatorDescription: String,
    },
}
</script>

<style scoped>

</style>
