<template>
    <div>
        <h4 class="text-primary fw-bold">Indicator:
            <span class="fw-lighter text-secondary">{{ indicatorName }}</span>
        </h4>
        <h6 class="text-primary fw-bold">Migratieachtergrond(en):
            <span class="fw-lighter text-secondary">{{ selectedOrigins }}</span>
        </h6>
        <h6 class="text-primary fw-bold">Uitsplitsing:
            <span class="fw-lighter text-secondary">{{ selectedBreakdown }}</span>
        </h6>
        <h6 class="text-warning fw-bold">Let op:
            <span class="fw-lighter text-secondary">
                    Als er voor een bepaalde uitsplitsing in stap 3 of stap 4 geen gegevens
                    beschikbaar zijn, dan wordt die uitsplitsing niet getoond in de tabel of grafiek.
            </span>
        </h6>
    </div>
</template>

<script>
export default {
    name: "DataSummary",
    data() {
        return {
            selectedIndicator: '',
            selectedBreakdown: '',
            selectedOrigins: '',
        }
    },
    props: {
        apiResponseParams: Object,
        indicatorName: String,
    },
    watch: {
        apiResponseParams() {
            this.selectedIndicator = this.apiResponseParams.selectedIndicator
            this.selectedOrigins = this.apiResponseParams.selectedOrigins.join(', ')
            this.selectedBreakdown = this.apiResponseParams.selectedBreakdown.toLowerCase() === 'iedereen' ? 'Geen'
                : this.apiResponseParams.selectedBreakdown
        }
    },
}
</script>
